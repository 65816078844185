/* You can add global styles to this file, and also import other style files */

.grecaptcha-badge {
  z-index: 200;
  width: 70px !important;
  left: 0 !important;
  transition: all 0.3s ease 0s !important;
  &:hover {
    width: 256px !important;
  }
}

.input-error {
  .hrb-input,
  .hrb-select-custom {
    border: 2px solid red;
    border-radius: 16px;
    color: red;
  }
}

span.error {
  color: red;
  margin-top: 10px;
}

.hrb-text--little{
  font-weight: 400;
}


body {
    background: #f1f5f7;
    .hideIframe { 
        position:absolute; 
        z-index: -1;
        top:-100px; 
        left:-100px; 
        width:1px; 
        height:1px; 
    }
}

textarea:focus, input:focus{
    outline: none;
}

span.input-placeholder.ng-star-inserted{
  font-family: "Graphik", "Helvetica Neue", "Helvetica", sans-serif !important
}

input[type="text"]
{
  font-family: "Graphik", "Helvetica Neue", "Helvetica", sans-serif !important
}

input[type="number"]
{
  font-family: "Graphik", "Helvetica Neue", "Helvetica", sans-serif !important
}


